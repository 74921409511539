<template>
    <b-row class="mt-1" align-v="center">
        <b-col md="1"><b>Kalan</b></b-col>
        <b-col md="1">
            <div>
                Adet:<br>
                Matrah:<br>
                Kdv:<br>
                Toptal:
            </div>
        </b-col>
        <b-col>
            <b-row>
                <b-col v-for="xcol in 12" :key="xcol" class="border text-right">
                    <div v-if="count(xcol - 1) ">
                        {{ count(xcol - 1) | currency('', 0,{symbolOnLeft: true}) }}<br>
                        {{ matrah(xcol - 1) | currency('₺  ', 0,{symbolOnLeft: true}) }} <br>
                        {{ tax(xcol - 1) | currency('₺  ', 0,{symbolOnLeft: true}) }} <br>
                        {{ amount(xcol - 1) | currency('₺  ', 0,{symbolOnLeft: true}) }}
                    </div>
                </b-col>
            </b-row>
        </b-col>


        <b-col md="1" class="border text-right">
            <div>
                {{ totalCount | currency('', 0,{symbolOnLeft: true}) }}<br>
                {{ totalMatrah | currency('₺  ', 0,{symbolOnLeft: true}) }}
                <br>
                {{ totalTax| currency('₺  ', 0,{symbolOnLeft: true}) }}
                <br>
                {{ totalAmount| currency('₺  ', 0,{symbolOnLeft: true}) }}
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "Remainder",
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    computed: {
        totalCount() {
            let total = 0;
            for (let i = 0; i < 12; i++) {
                total += this.count(i);
            }
            return total;
        },
        totalMatrah() {
            let total = 0;
            for (let i = 0; i < 12; i++) {
                total += this.matrah(i);
            }
            return total;
        },
        totalTax() {
            let total = 0;
            for (let i = 0; i < 12; i++) {
                total += this.tax(i);
            }
            return total;
        },
        totalAmount() {
            let total = 0;
            for (let i = 0; i < 12; i++) {
                total += this.amount(i);
            }
            return total;
        },
    },
    methods: {
        count(key) {
            if (!this.company.invoices[key]) return 0;
            let remainder = this.company.invoices[key].count;
            let total = 0;
            this.company.stores.forEach(store => {
                if (store.invoices[key])
                    total += store.invoices[key].count;
            })
            return remainder - total;
        },
        matrah(key) {
            if (!this.company.invoices[key]) return 0;
            let remainder = this.company.invoices[key].matrah;
            let total = 0;
            this.company.stores.forEach(store => {
                if (store.invoices[key])
                    total += store.invoices[key].matrah;
            })
            return remainder - total;
        },
        tax(key) {
            if (!this.company.invoices[key]) return 0;
            let remainder = this.company.invoices[key].tax;
            let total = 0;
            this.company.stores.forEach(store => {
                if (store.invoices[key])
                    total += store.invoices[key].tax;
            })
            return remainder - total;
        },
        amount(key) {
            if (!this.company.invoices[key]) return 0;
            let remainder = this.company.invoices[key].amount;
            let total = 0;
            this.company.stores.forEach(store => {
                if (store.invoices[key])
                    total += store.invoices[key].amount;
            })
            return remainder - total;
        }
    }
}
</script>

<style scoped>

</style>
