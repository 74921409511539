<template>
  <div>
    <Company v-for="company in _statistics" :key="company.id" :company="company"></Company>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Company from "@/views/invoice/statistic/Company.vue";

export default {
  name: "Statistics",
  components: {Company},
  computed: {
    ...mapGetters('invoice', ["_statistics", "_others"]),
  },
  mounted() {
    this.$store.dispatch('invoice/getStatistics');
  }
}
</script>

<style scoped>

</style>
