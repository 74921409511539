<template>
  <b-card>
    <b-row>
      <b-col md="1"><b>{{ company.short_name }}</b></b-col>
      <b-col md="1"><b>Aylar</b></b-col>
      <b-col>
        <b-row>
          <b-col v-for="xcol in 12" :key="xcol" class="border text-right">
            <b>{{ xcol }}</b>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="1" class="border"><b>Toplam (Ay)</b></b-col>
    </b-row>
    <b-row>
      <b-col class="bv-example-row bv-example-row-flex-cols">
        <Store v-for="store in company.stores"
               :key="store.id"
               :name="store.store_name"
               :invoices="store.invoices"
        ></Store>
        <Remainder :company="company"></Remainder>
        <Store :invoices="company.invoices" name="Toplam"></Store>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import Store from "@/views/invoice/statistic/Store.vue";
import Remainder from "@/views/invoice/statistic/Remainder.vue";

export default {
  name: "Company",
  components: {Store, Remainder},
  props: {company: {type: Object, required: true}}
}
</script>

<style scoped>

</style>
